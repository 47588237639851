<!-- components/LeftSidePage.vue -->
<template>
    <ul>
      <NuxtLink to="/categories" class="flex justify-between relative px-md gap-[0.5rem] text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-slate-200 text-sm tracking-tighter font-medium cursor-pointer rounded p-2 -outline-offset-1 s:rounded-[8px] bg-transparent no-underline">
        Crea una comunidad
      </NuxtLink>
      <li v-for="category in categoriesStore.categories" :key="category.id" class="relative list-none mt-0">
        <NuxtLink :to="`/${category.slug}`" class="flex justify-between relative px-md gap-[0.5rem] text-slate-900 hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-slate-200 text-sm tracking-tighter font-medium cursor-pointer rounded p-2 -outline-offset-1 s:rounded-[8px] bg-transparent no-underline">{{ category.name }}</NuxtLink>
      </li>
    </ul>
</template>

<script setup lang="ts">
import { useCategoriesStore } from '~/stores/categories'

const categoriesStore = useCategoriesStore()

onMounted(() => {
  categoriesStore.fetchCategories()
})
</script>