<template>
  <!-- header class="w-full fixed top-0 bg-white shadow-md z-10 border-b dark:border-slate-700 dark:bg-slate-900">
    <div class="container mx-auto p-4 flex items-center justify-between w-max-2/3" -->
      <NuxtLink to="/">
        <img src="~assets/images/logo.svg" class="w-10">
      </NuxtLink>

      <UInput
        icon="i-material-symbols-light-search"
        placeholder="buscar..."
        class="w-96"/>

      <!-- nav -->
        <ul class="flex">
          <li>
            <UButton
              icon="i-material-symbols-light-sunny-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              color="primary"
              variant="ghost"
              @click="changeColor"
            />
          </li>

          <li v-if="isAuthenticated">
            <UButton
              to="/submit"
              icon="i-material-symbols-light-publish"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              variant="ghost"
            />
          </li>

          <!-- li v-if="isAuthenticated && post.user.avatar != null" class="self-center p-2">
            <UAvatar
              src="https://avatars.githubusercontent.com/u/14201880?v=4"
              size="xs"
            />
          </li -->

            <li>
            <UButton
              to="/profile"
              icon="i-material-symbols-light-account-circle"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              variant="ghost"
            />
          </li>

          <li v-if="isAuthenticated">
            <UButton
              icon="i-material-symbols-light-power-settings-new-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              variant="ghost"
              @click="logoutOpen = true"
            />

            
            <UModal v-model="logoutOpen" >
              <div class="p-4 self-center text-center m-4">
                <p class="mb-2 font-light">¿Deseas salir?</p>
                <UButton
                  class="mr-2"
                  color="red"
                  @click="logout">
                  Salir
                </UButton>
                
                <UButton label="Cancelar" @click="logoutOpen = false" />
              </div>
            </UModal>
          </li>

          
          <li v-else>
            <UButton
              icon="i-material-symbols-login"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              variant="ghost"
              @click="loginOpen = true" 
            />

            <UModal v-model="loginOpen">
                <AuthLoginForm :token="resetToken" />
                 <!-- LoginPage / -->
            </UModal>

            <UButton
              icon="i-material-symbols-person-raised-hand-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="xl"
              padding="xl"
              variant="ghost" 
            />
          </li>

      </ul>
    <!-- /nav>
  </div>
  </header -->
</template>

<script lang="ts" setup>

/*
definePageMeta({
  middleware: ['sanctum:guest'],
})
*/

const { isAuthenticated, logout } = useSanctumAuth()
const logoutOpen = ref(false)
const loginOpen = ref(false)

const colorMode = useColorMode();
const changeColor = () => (colorMode.preference = (colorMode.value === 'light' ? 'dark' : 'light'))

</script>

<style>

</style>
