<template>
  <div>
  <header class="fixed top-0 left-0 right-0 h-24 z-50 border-b border-gray-200 dark:border-slate-800 bg-white dark:bg-slate-900">
    <nav class="h-full flex items-center justify-evenly">
      <PageHeader />
    </nav>
  </header>

  <!-- Contenedor principal con padding-top y centrado -->
  <div class="pt-24 min-h-screen flex justify-center">

    <!-- Contenedor de las tres columnas con ancho 2/3 -->
    <div class="lg:w-2/3 w-full flex relative">
    
    <!-- Sidebar izquierdo -->
      <aside class="fixed w-[13.33vw] h-[calc(100vh-6rem)] overflow-y-auto border-r border-gray-200 dark:border-slate-800 invisible md:visible">
        <div class="p-4">
          <LeftSidePage />
        </div>
      </aside>

      <!-- Contenido principal - scrolleable -->
      <main class="lg:w-[50vw] w-full ml-0 min-h-[calc(100vh-6rem)] md:ml-[13.33vw]">
        <div class="p-4">
          
          <div>
            <slot />
          </div>
        </div>
      </main>

      <!-- Sidebar derecho - fijo -->
      <aside class="fixed right-[16.67vw] w-[13.33vw] h-[calc(100vh-6rem)] overflow-y-auto hidden lg:block">
        <div class="p-4">
          <!-- h2 class="font-semibold">Right</h2 -->
        </div>
      </aside>
    </div>
  </div></div>
<!-- /body -->
</template>

<script lang="ts" setup>
//import LeftSidePage from '~/components/LeftSidePage.vue';
//import { useCategoriesStore } from '~/stores/categories'



const categoriesStore = useCategoriesStore()
onServerPrefetch(async () => {
  if (!categoriesStore.categories.length) {
    await categoriesStore.fetchCategories()
  }
})

/*
useHead({
  bodyAttrs: {
    class: 'antialiased dark:bg-slate-900 dark:text-slate-100',
  },
})



useSeoMeta({
  title: 'Espiker.com',
  description: '[description]',
  ogTitle: 'Espiker.com',
  ogDescription: '[og:description]',
  ogImage: '[og:image]',
  ogUrl: '[og:url]',
  twitterTitle: '[twitter:title]',
  twitterDescription: '[twitter:description]',
  twitterImage: '[twitter:image]',
  twitterCard: 'summary'
})
*/
</script>

<style scoped>

</style>
